<template>
  <div class="sideform">
    <div class="sideform-header">
      <ul>
        <li v-if="$route.query.id">
          <h3>{{ area.name }}</h3>
        </li>
        <li v-else></li>
      </ul>
    </div>

    <div class="form scrollable-auto">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">DT Code</label>
            <div class="control">
              <input type="text" class="input" v-model="new_ZoneCode" placeholder="DT Code" />
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">DT Name</label>
            <div class="control">
              <input type="text" class="input" v-model="new_Name" placeholder="DT Name" />
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">Code</label>
            <div class="control">
              <input type="text" class="input" v-model="newCode" placeholder="Code" />
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">DE</label>
            <div class="control">
              <input type="text" class="input" v-model="newDE" placeholder="DE" />
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">ASM</label>
            <div class="control">
              <input type="text" class="input" v-model="newASM" placeholder="ASM" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sideform-footer">
      <button v-if="$route.query.id" class="button primary" @click="UpdateZone">
        Update
      </button>
      <button v-else class="button primary" @click="AddZone">Save</button>
      <button class="button grey" @click="close">Cancel</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["propFormActive"],
  data: () => ({
    area: {},
    new_ZoneCode: "",
    new_Name: "",
    newCode: "",
    newDE: "",
    newASM: "",
  }),
  watch: {
    "$route.query.add"(val) {
      if (val) {
        this.new_ZoneCode = "";
        this.new_Name = "";
        this.newCode = "";
        this.newDE = "";
        this.newASM = "";
      }
    },
    "$route.query.id": {
      handler(val) {
        if (val) {
          this.FetchZoneId();
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$router
        .push({
          path: "/dt-list",
        })
        .catch((err) => { });
    },
    AddZone() {
      //
      //Alert Loading
      this.$swal({
        title: "Loading...",
        html: "Please wait",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })

      this.$axios
        .post(`${this.$server}api/zone-add`, {
          zoneCode: this.new_ZoneCode,
          name: this.new_Name,
          code: this.newCode,
          DE: this.newDE,
          ASM: this.newASM,
        })
        .then(() => {
          setTimeout(() => {
            this.$swal({
              position: "top",
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1000,
            });
            this.$emit("close");
            this.$router
              .push({
                path: "/dt-list",
              })
              .catch((err) => { });
          }, 500);
        });
    },
    FetchZoneId() {
      this.$axios
        .get(`${this.$server}api/zone-findid/` + this.$route.query.id)
        .then((res) => {
          this.area = res.data.mapIdZone;
          (this.new_ZoneCode = this.area.zoneCode),
            (this.new_Name = this.area.name),
            (this.newCode = this.area.code),
            (this.newDE = this.area.DE),
            (this.newASM = this.area.ASM);
        });
    },
    async UpdateZone() {
      //
      //Alert Loading
      this.$swal({
        title: "Loading...",
        html: "Please wait",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })

      const udpateZone = {
        id: this.$route.query.id,
        zoneCode: this.new_ZoneCode,
        name: this.new_Name,
        code: this.newCode,
        DE: this.newDE,
        ASM: this.newASM,
      };
      await this.$axios
        .put(`${this.$server}api/zone-update`, udpateZone)
        .then(() => {
          setTimeout(() => {
            this.$swal({
              position: "top",
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1000,
            });
            this.$emit("close");
            this.$router
              .push({
                path: "/dt-list",
              })
              .catch((err) => { });
          }, 500);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>