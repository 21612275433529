<template>
  <div class="page-container">
    <div class="page-content" :class="{ small: formActive }">
      <div class="page-header" v-if="!formActive">
        <div class="page-header-start">
          <h3>DT List - Outlet</h3>
          <!-- <div class="button-group">
            <button
              class="button primary"
              @click="
                formActive = true;
                $router
                  .push({
                    ...$route,
                    query: {
                      ...$route.query,
                      add: 'add',
                    },
                  })
                  .catch((err) => {});
              "
            >
              Add
            </button>
            <button class="button light-grey" @click="ExportOutletChampa">
              Export
            </button>
          </div> -->
          <div class="button-group">
            <button class="button grey" @click="close">Cancel</button>
          </div>
        </div>

        <div class="page-header-end">
          <input
            v-model="searchOutlet"
            type="text"
            class="input"
            placeholder="Search..."
          />
        </div>

        <div class="page-header-end">
          <ul>
            <li>
              <p>All Outlet:</p>
              <h3>{{ totalShop }}</h3>
            </li>
            <li>
              <div class="select">
                <filterSelect
                  v-model="selectedSupervisor"
                  :data="supervisor"
                  itemName="name"
                  optionName="Supervisor"
                />
              </div>
            </li>
            <li>
              <div class="select">
                <filterSelect
                  v-model="selectedStatus"
                  :data="status"
                  itemName="name"
                  optionName="Status"
                />
              </div>
            </li>
            <li style="width: 155px">
              <div class="select">
                <filterSelect
                  v-model="selectDT"
                  :data="getDT"
                  itemName="zoneCode"
                  optionName="DT"
                />
              </div>
            </li>
            <!-- <li>
              <div class="select">
                <select disabled>
                  <option>{{ new_ZoneCode }}</option>
                </select>
              </div>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="table-container scrollable">
        <table class="table">
          <thead>
            <tr>
              <th class="is-xs">Outlet Code</th>
              <th class="is-sm">Outlet Name</th>
              <th class="is-sm">Outlet Status</th>
              <th class="is-sm">Recurit Date</th>
              <th class="is-sm">Quit Date</th>
              <th class="is-xxs">DT Code</th>
              <th class="is-xs">Working ID</th>
              <th class="is-xs">Employee ID</th>
              <th class="is-xs">Champa Name</th>
              <th class="is-xs">Join Date</th>
              <th class="is-xs text-right">Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in getShop" :key="index">
              <td
                class="clickable"
                @click="
                  formActive = true;
                  $router
                    .push({
                      query: {
                        selectedSupervisor: $route.query.selectedSupervisor,
                        selectedStatus: $route.query.selectedStatus,
                        page: $route.query.page,
                        perPage: pages.perPage,
                        searchOutlet: $route.query.searchOutlet,
                        selectDT: $route.query.selectDT,
                        type: $route.query.type,
                        shopId: i._id,
                      },
                    })
                    .catch((err) => {});
                "
              >
                {{ i.shopCode }}
              </td>
              <td>{{ i.name }}</td>
              <td>{{ getChampaOutlet(i.status) }}</td>
              <td>{{ formatDate(i.recruitDate) }}</td>
              <td>{{ formatDate(i.quitDate) }}</td>
              <td>{{ i.zoneCode }}</td>
              <td>{{ i.workingID }}</td>
              <td>{{ i.employeeID }}</td>
              <td>{{ i.fullname }}</td>
              <td>{{ formatDate(i.joinDate) }}</td>
              <td class="text-right">{{ i.adminName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- pagination -->
      <paginate v-model="page" :total="all" />
    </div>

    <transition name="slide-fade">
      <outletForm v-if="formActive" @close="formActive = false" />
    </transition>

    <!-- <export-date
      v-if="formActiveExport"
      @closeExport="formActiveExport = false"
    /> -->
  </div>
</template>

<script>
import paginate from "./paginate.vue";
import filterSelect from "./filter/filter.vue";
import outletForm from "./form/outlet.vue";
import ExportDate from "./export/export-date.vue";
import moment from "moment";
export default {
  components: {
    filterSelect,
    paginate,
    outletForm,
    ExportDate,
  },
  data: () => ({
    status: [
      {
        _id: "setup",
        name: "Setup",
      },
      {
        _id: "pending",
        name: "No Champa",
      },
      {
        _id: "active",
        name: "Active",
      },
      {
        _id: "quit",
        name: "Quit",
      },
    ],
    formActive: false,
    formActiveExport: false,
    getShop: [],
    supervisor: [],
    getDT: [],
    findIdDT: {},
    new_ZoneCode: "",
    totalShop: "",
    pages: {
      perPage: 10,
    },
    page: 1,
    all: null,
    selectedSupervisor: "",
    selectedStatus: "",
    searchOutlet: "",
    selectDT: "",
    type: "",
  }),
  watch: {
    //supervisor
    "$route.query.selectedSupervisor": {
      handler() {
        this.FetchShop();
      },
      immediate: true,
    },
    selectedSupervisor: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: { ...this.$route.query, selectedSupervisor: val },
        });
      },
    },
    //status
    "$route.query.selectedStatus": {
      handler(val) {
        this.FetchShop();
      },
      immediate: true,
    },
    selectedStatus: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, selectedStatus: val },
        });
      },
    },
    //paginations
    "$route.query.page": {
      handler(val) {
        this.FetchShop();
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, page: val },
        });
      },
    },
    //search
    "$route.query.searchOutlet": {
      handler(val) {
        this.FetchShop();
      },
      immediate: true,
    },
    searchOutlet: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, searchOutlet: val },
        });
      },
    },
    //selectDT
    "$route.query.selectDT": {
      handler() {
        this.FetchShop();
      },
      immediate: true,
    },
    selectDT: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: { ...this.$route.query, selectDT: val },
        });
      },
    },
    // type
    "$route.query.type": {
      handler() {
        this.FetchShop();
      },
      immediate: true,
    },
    type: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: { ...this.$route.query, type: val },
        });
      },
    },
    $route() {
      this.FetchShop();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
  },
  created() {
    this.FetchShop();
    this.FetchSupervisor();
    this.FetchDtId();
    this.FetchDT();
  },
  computed: {
    getChampaOutlet() {
      return (val) => {
        if (val === "pending") return "no champa";
        return val;
      };
    },
    formatDate() {
      return (val) => {
        if (val) return moment(val).format("YYYY-MM-DD");
      };
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$router
        .push({
          path: "/dt-list",
        })
        .catch((err) => {});
    },
    async FetchDtId() {
      const res = await this.$axios.get(
        `${this.$server}api/zone-findid/` + this.$route.query.selectDT
      );
      this.findIdDT = res.data.mapIdZone;
      this.new_ZoneCode = this.findIdDT.zoneCode;
    },
    async FetchDT() {
      const res = await this.$axios.get(`${this.$server}api/zone-get`);
      this.getDT = res.data.mapGetZone;
    },
    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.supervisorAll = res.data.mapAdmin;
      this.supervisor = this.supervisorAll.filter(
        (i) => i.typeAdmin === "supervisor"
      );
    },
    async FetchShop() {
      const res = await this.$axios.get(
        `${this.$server}api/aggre-shop-champa?adminId=${this.$route.query.selectedSupervisor}&status=${this.$route.query.selectedStatus}&page=${this.$route.query.page}&perPage=${this.pages.perPage}&search=${this.$route.query.searchOutlet}&zoneId=${this.$route.query.selectDT}&typeShop=${this.$route.query.type}`
      );
      this.getShop = res.data.aggreShopChampa;
      this.totalShop = res.data.total;
      this.all = res.data.total;
    },
    async ExportOutletChampa() {
      try {
        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-shop-champa`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "OutletChampa.xlsx");
        document.body.appendChild(link);
        link.click();
        this.$swal({
          position: "top",
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1000,
        });
        // this.$emit("closeExport");
        // await this.$store.dispatch("completed")
      } catch (error) {
        const errorText = response.data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  position: absolute;
  top: -5px;
  background: #ffff;
  height: 100%;
  width: 100%;
  padding-right: 15px;
}
.table-container {
  padding: 0 10px 0 0;
  .table {
    width: 100%;
  }
  .table tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
  }
}
</style>
