<template>
  <section class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="page-header-start">
          <h3>DT List</h3>
          <button
            class="button primary"
            @click="
              formActive = true;
              $router
                .push({
                  query: {
                    add: 'add',
                  },
                })
                .catch((err) => {});
            "
          >
            Add
          </button>
        </div>
      </div>

      <div class="table-container">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th class="is-xs">DT Code</th>
              <th>Area Name</th>
              <th>Code</th>
              <th>DE</th>
              <th>ASM</th>
              <th class="is-xxs">No. of Shop</th>
              <!-- <th class="is-xxs">Option</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in zone" :key="(n = index)">
              <td
                @click="
                  formActive = true;
                  $router
                    .push({
                      query: {
                        id: i._id,
                      },
                    })
                    .catch((err) => {});
                "
              >
                {{ i.zoneCode }}
              </td>
              <td>{{ i.name }}</td>
              <td>{{ i.code }}</td>
              <td>{{ i.DE }}</td>
              <td>
                {{ i.ASM }}
              </td>
              <td
                class="text-right"
                @click="
                  formDtList = true;
                  $router
                    .push({
                      query: {
                        selectedSupervisor: '',
                        selectedStatus: '',
                        page: 1,
                        perPage: 10,
                        searchOutlet: '',
                        selectDT: i._id,
                        type: '',
                      },
                    })
                    .catch(() => {});
                "
              >
                {{ i.shopsCount }}
              </td>
              <!-- <td>
                <div class="icons">
                  <div class="icon alert" @click="DeleteZone(i._id)">
                    <i class="fa-solid fa-trash"></i>
                  </div>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <transition name="slide-fade">
        <dtListOutlet v-if="formDtList" @close="formDtList = false" />
      </transition>
    </div>

    <areaForm v-if="formActive" @close="formActive = false" />
  </section>
</template>

<script>
import areaForm from "../components/form/area.vue";
import dtListOutlet from "../components/dt-list-outlet.vue";
export default {
  components: {
    areaForm,
    dtListOutlet,
  },
  data: () => ({
    isLoading: false,
    formActive: false,
    formDtList: false,
    zone: [],
    linkValue: "https://time.hr-sabai.com/",
  }),
  watch: {
    $route() {
      this.FetchZone();
    },
  },
  created() {
    this.FetchZone();
  },
  methods: {
    FetchZone() {
      this.$axios.get(`${this.$server}api/zone-get`).then((res) => {
        this.zone = res.data.mapGetZone;
      });
    },
    async DeleteZone(id) {
      await this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios.delete(`${this.$server}api/zone-delete/${id}`);
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
        }
        this.FetchZone();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  display: flex;

  button {
    margin-left: 20px;
  }
}

ul {
  display: flex;

  li {
    padding: 10px;
  }
}

.table-container {
  height: 100%;
}

.input {
  font-size: 16px;
  background-color: transparent;
  border-width: 0 !important;
  background-color: var(--light-grey-color);
}
</style>
