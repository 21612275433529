var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-container"},[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header-start"},[_c('h3',[_vm._v("DT List")]),_c('button',{staticClass:"button primary",on:{"click":function($event){_vm.formActive = true;
            _vm.$router
              .push({
                query: {
                  add: 'add',
                },
              })
              .catch(function (err) {});}}},[_vm._v(" Add ")])])]),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table is-fullwidth"},[_vm._m(0),_c('tbody',_vm._l((_vm.zone),function(i,index){return _c('tr',{key:(_vm.n = index)},[_c('td',{on:{"click":function($event){_vm.formActive = true;
                _vm.$router
                  .push({
                    query: {
                      id: i._id,
                    },
                  })
                  .catch(function (err) {});}}},[_vm._v(" "+_vm._s(i.zoneCode)+" ")]),_c('td',[_vm._v(_vm._s(i.name))]),_c('td',[_vm._v(_vm._s(i.code))]),_c('td',[_vm._v(_vm._s(i.DE))]),_c('td',[_vm._v(" "+_vm._s(i.ASM)+" ")]),_c('td',{staticClass:"text-right",on:{"click":function($event){_vm.formDtList = true;
                _vm.$router
                  .push({
                    query: {
                      selectedSupervisor: '',
                      selectedStatus: '',
                      page: 1,
                      perPage: 10,
                      searchOutlet: '',
                      selectDT: i._id,
                      type: '',
                    },
                  })
                  .catch(function () {});}}},[_vm._v(" "+_vm._s(i.shopsCount)+" ")])])}),0)])]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.formDtList)?_c('dtListOutlet',{on:{"close":function($event){_vm.formDtList = false}}}):_vm._e()],1)],1),(_vm.formActive)?_c('areaForm',{on:{"close":function($event){_vm.formActive = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"is-xs"},[_vm._v("DT Code")]),_c('th',[_vm._v("Area Name")]),_c('th',[_vm._v("Code")]),_c('th',[_vm._v("DE")]),_c('th',[_vm._v("ASM")]),_c('th',{staticClass:"is-xxs"},[_vm._v("No. of Shop")])])])}]

export { render, staticRenderFns }